// Helpers
import api from 'helpers/api';

const AppReducer = (state, action) => {

    // Clone existing state
    let clonedState = {...state};
    // Determing course of action
    switch (action.type) {

        case 'ADD_SITEMAP_TIER':
            // Add New Tier Object to State
            addTier(clonedState, action.tier);
            return clonedState;

        case 'DELETE_SITEMAP_TIER':
            // Remove from State
            if (clonedState.sitemap.data[action.field] !== undefined) {
                removeTier(clonedState, action.field);
            }
            return clonedState;

        case 'DELETE_SITEMAP_SITE_ITEM':
            // Remove from State
            if (clonedState.sitemap.data[action.tier] !== undefined && clonedState.sitemap.data[action.tier].site_items[action.site_item] !== undefined) {
                delete clonedState.sitemap.data[action.tier].site_items[action.site_item];
            }
            if (clonedState.sitemap.data[action.tier] !== undefined && clonedState.sitemap.data[action.tier].site_items["'" + action.site_item + "'"] !== undefined) {
                delete clonedState.sitemap.data[action.tier].site_items["'" + action.site_item + "'"];
            }
            return clonedState;

        case 'UPDATE':
            // Update and Replace original State
            if (action.field.includes('.')) {
                let split = action.field.split('.');
                // @todo: Change this to a function that takes a length and maps appropriately.
                switch (split.length) {
                    case 2:
                        clonedState[split[0]][split[1]] = action.payload;
                        break;
                    case 3:
                        clonedState[split[0]][split[1]][split[2]] = action.payload;
                        break;
                    case 4:
                        clonedState[split[0]][split[1]][split[2]][split[3]] = action.payload;
                        break;
                    case 5:
                        clonedState[split[0]][split[1]][split[2]][split[3]][split[4]] = action.payload;
                        break;
                    default:
                        break;
                }
            } else {
                clonedState[action.field] = action.payload;
            }
            return clonedState;

        // User Functions
        case 'LOGIN':
            // Log in fields:
            clonedState.user = action.payload;
            if (action.updateMyLicensees) {
                api.post("licensees/me", {
                    body: JSON.stringify({
                       user_id: action.payload.id
                    })
                })
                .then(data => {
                    // Alphabetise the List
                    data.result.licensee_user_roles.sort(function(a,b) {
                            if (a.licensee['company_name'].toLowerCase() < b.licensee['company_name'].toLowerCase()) return -1;
                            if (b.licensee['company_name'].toLowerCase() < a.licensee['company_name'].toLowerCase()) return 1;
                            return 0;
                    });
                    clonedState.user.licensee_user_roles = data.result.licensee_user_roles;
                });
            }
            return clonedState;
        case 'LOGOUT':
            // Log out fields:
            clonedState.user = {
                id: null,
                first_name: null,
                last_name: null,
                avatar_seed: null,
                photo: null,
                photo_thumb: null
            };
            clonedState.licensee = {
                id: null
            };
            clonedState.site = {
                id: null,
                display_id: null
            };
            return clonedState;

        default:
            console.log('default switch state in appreducer - if you see this, you probably have forgotten to define a `dispatch` method');
            console.log(action);
            return state;
    }
};

/**
 * Adds a new Tier to our State
 * 
 * @param {object} clonedState
 * @param {object} tier
 */
const addTier = (clonedState, tier) => {
    // Add our new Tier
    clonedState.sitemap.data[tier.id] = {
        data: tier,
        site_items: [],
        children: []
    };
    // Also add it's ID to the children of it's parent
    let parent_id = (tier.parent_id === null ? 'site' : tier.parent_id);
    if (!clonedState.sitemap.data[parent_id].children.includes(tier.id)) {
        clonedState.sitemap.data[parent_id].children.push(tier.id);
    }
};

/**
 * Removes a Tier from our State.
 * Also removes any children Tiers, and the reference to this Tier in it's Parent's children list.
 * 
 * @param {object} clonedState
 * @param {int} tier_id
 */
const removeTier = (clonedState, tier_id) => {
    // Check if it's got children.. we'll remove them too.
    // Clone childlist so we don't lose it.
    let childrenToRemove = [...clonedState.sitemap.data[tier_id].children];
    if (childrenToRemove.length > 0) {
        // Foreach children, recusrsively call this function on them..
        childrenToRemove.forEach((child_id) => {
            removeTier(clonedState, child_id);
        });
    }

    // Check for and remove from parent's list of children.
    let parent_id = clonedState.sitemap.data[tier_id].data.parent_id;
    // If parent_id is NULL, then it's a top-level tier - remove from 'site' children
    if (parent_id === null) {
        parent_id = 'site';
    }
    var index = clonedState.sitemap.data[parent_id].children.indexOf(parseInt(tier_id));
    if (index !== -1) {
        clonedState.sitemap.data[parent_id].children.splice(index, 1);
    }

    // Remove the Tier itself.
    delete clonedState.sitemap.data[tier_id];
};

export default AppReducer;
