// React
import React, {
    lazy,
    Suspense,
    useContext,
    useEffect,
} from 'react';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";

// Other Imports
import Cookies from 'universal-cookie';
import { decompressFromBase64 as lzStringDecompress } from 'lz-string';

// Context / State
import {GlobalContext} from 'context/GlobalState';

// Helpers
import config from 'helpers/config';

// Styles
import ('styles/App.css' /* webpackChunkName: "App" */);

// Content
const Main = lazy(() => import('Main' /* webpackChunkName: "Main" */).catch(e => window.location.reload()));

const App = () => {
    const loadingDiv = <div></div>;
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {selectLicensee, selectSite, logUserIn, updateUserCookie} = useContext(GlobalContext);
    const locationArray = useLocation().pathname.substring(1).split( '/' );

    useEffect(() => {
        /**
         * Load the User State from their previously stored Cookie
         */
        if (typeof(cookies.get(config.environmentShort + 'Data')) !== 'undefined') {
            // Decrypt the stored state and store it in the App state.
            var decryptedStateData = JSON.parse(lzStringDecompress(cookies.get(config.environmentShort + 'Data')));
            // Check for a stored AppVersion. If out of date, force refresh.
//            if (typeof(decryptedStateData.AppVersion) === 'undefined' || decryptedStateData.AppVersion !== this.state.AppVersion) {
//                console.log('Sorry! There has been an update to our software, and you will need to log in again.');
//                // Outdated or non-existant Version. Force refresh.
//                cookies.remove(config.environmentShort + 'AT', {path: '/', domain: config.siteDomain});
//                cookies.remove(config.environmentShort + 'Data', {path: '/', domain: config.siteDomain});
//                // Reload to start over
//                window.setTimeout(()=> {
//                    navigate(0);
//                }, 1);
//            } else {
                    // Current Version - set to state and continue to load.
                    // When logging in from State Data, we will also update the Licensee Access List
                    logUserIn(decryptedStateData, true);
                    updateUserCookie();

                    // Don't redirect to home screen if we're on specific routes.
                    let noRedirect = [
                        'items',
                        'users',
                        'wb',
                        'onboarding'
                    ];

                    // If User has no Licensees, redirect to /onboarding
                    if (decryptedStateData.licensee_user_roles.length === 0) {
                        navigate('/onboarding');
                    } else {
                        // If accessing a Site directly, Select it, and it's Licensee if possible.
                        if (locationArray[0] === 'sites' && !!locationArray[1] && locationArray[1] !== 'add') {
                            selectSite(decryptedStateData.id, locationArray[1], decryptedStateData.last_accessed_licensee_id);
                        } else if (!!decryptedStateData.last_accessed_site_id) {
                            selectSite(decryptedStateData.id, decryptedStateData.last_accessed_site_id, decryptedStateData.last_accessed_licensee_id, !noRedirect.includes(locationArray[0]));
                        } else {
                            // Use their most recent Licensee
                            if (!!decryptedStateData.last_accessed_licensee_id) {
                                selectLicensee(decryptedStateData.id, decryptedStateData.last_accessed_licensee_id, !noRedirect.includes(locationArray[0]));
                            } else {
                                // Default to the first one in the array
                                selectLicensee(decryptedStateData.id, decryptedStateData.licensee_user_roles[0].licensee_id, !noRedirect.includes(locationArray[0]));
                            }
                        }
                    }
//            }
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Suspense fallback={loadingDiv}>
            <Main
                loadingDiv={loadingDiv}
            />
        </Suspense>
    );
};
export default App;
