import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';

// Context / State
import {GlobalProvider} from 'context/GlobalState';

// Globally set Monday as First Day of Calendar - Used by DatePickers
import moment from 'moment';
moment.updateLocale('en', {
    week: {
        dow: 1 // 1 = Monday
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <GlobalProvider>
            <App />
        </GlobalProvider>
    </BrowserRouter>
);
