const {
    REACT_APP_API_URL,
    REACT_APP_DOMAIN,
    REACT_APP_ENVIRONMENT,
    REACT_APP_ENVIRONMENT_SHORT,
    REACT_APP_SITE_URL
} = process.env;

const imageIcon = (filename) => {
    let extension = filename.split('.').pop();
    
    // We have a base set of icons that we have images for - sourced from https://www.flaticon.com/packs/file-types
    var iconFiles = ['ae','ai','au','br','css','csv','dbf','doc','dw','dwg','exe','fl','fla','fw','html','in','iso','jpg','js','json','mp3','mp4','pdf','pl','png','ppt','pr','ps','psd','rtf','svg','txt','xls','xml','zip'];

    // If we have the icon, return it, otherwise, just use a default 'file' image.
    return '/img/icons/' + (iconFiles.includes(extension) ? extension : 'file') + '.png';
};

const imagePreviewExtensions = [
    'avif',
    'bmp',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'webp'
];

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    apiUrl: REACT_APP_API_URL,
    siteDomain: REACT_APP_DOMAIN,
    siteUrl: REACT_APP_SITE_URL,
    environment: REACT_APP_ENVIRONMENT,
    environmentShort: REACT_APP_ENVIRONMENT_SHORT,
    imageIcon: imageIcon,
    imagePreviewExtensions: imagePreviewExtensions
}
