import 'whatwg-fetch'; 
import config from './config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const isAuthToken = () => {
    return (cookies.get(config.environmentShort + 'AT') !== null);
};

const request = method => async (resource, options = {}) => {
    const { apiUrl } = config;
  
    if (!resource || !apiUrl) return null;
    return await fetch(`${apiUrl}/${resource}`, {
        method,
        headers: (isAuthToken() ? 
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.get(config.environmentShort + 'AT')
            }
        :
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        ),
        ...options
    })
    .then((result) => {
        if (result.url.includes('downloadFiles')) {
            return result.blob();
        } else {
            return result;
        }
    })
    .then((result) => {
        // If we have a Blob (i.e. a File), open a new window with the content to trigger download
        if (result instanceof Blob) {
            let file = window.URL.createObjectURL(result);
            var a = document.createElement("a");
            a.style = "display: none";
            a.href = file;
            a.download = 'download.zip';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(file);
            a.remove();
        } else if (result.ok) {
            return result.json();
        } else {
            return result.text().then(bodyText => {
                if (bodyText === '' && result.statusText !== '') {
                    return '{"status":"NOK","result":{"error":"' + result.statusText + '"}}';
                }
                return '{"status":"NOK","result":{"error":"' + bodyText + '"}}';
            });
        }
    })
    .catch((error) => {
        return '{"status":"NOK","result":{"error":"Unknown Error"}}';
    });
};

const mapUrlParams = (paramsObject) => {
    return Object.keys(paramsObject).map(key => key + '=' + paramsObject[key]).join('&');
};

const API = {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE'),
    mapUrlParams
};

export default API;
